var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Legal = (function() {

            var self;
            var routerAnchorPos;

            function Legal(animations) {
                self = this;
                self.timeline = animations.timeline;
                
                $("#legal-mast .content-wrapper").mCustomScrollbar();
            }

            Legal.prototype.enter = function() {

                $('body, html').css({'overflow-y': 'hidden'});
                self.timeline.set('#legal, #legal section', { display: 'block', opacity: 0 });
                self.timeline.set('#legal-mast', { opacity: 1 });
                self.timeline.to('#legal', 0.5, { opacity: 1 });
            };

            Legal.prototype.leave = function() {
                self.timeline.to('#legal', 0.5, { display: 'none', opacity: 0 });
                $('body, html').css({'overflow-y': 'visible'});
            };

            Legal.prototype.handleWindowResize = function() {
            };

            return Legal;
        })();

        Controllers.Legal = Legal;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));