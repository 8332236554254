var Aneox;
(function(Aneox) {
    'use strict';

    // Lazysizes - add simple support for background images
    document.addEventListener('lazybeforeunveil', function(e) {
        var bg = e.target.getAttribute('data-bg');
        if (bg) {
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });
   

    window.rootPath = _.trim(_.get(window, 'rootPath', ''));

    // Main bootlejs init
    var bottle = new Bottle();

    bottle.service('Library.Fonts', Aneox.Library.Fonts);
    bottle.service('Library.Locale', Aneox.Library.Locale);
    bottle.service('Library.Router', Aneox.Library.Router);
    bottle.service('Library.Analytics', Aneox.Library.Analytics, 'Library.Router');

    bottle.service('Animations', Aneox.Animations);

    bottle.service('Controllers.Home', Aneox.Controllers.Home, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Maison', Aneox.Controllers.Maison, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Philosophie', Aneox.Controllers.Philosophie, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Vinification', Aneox.Controllers.Vinification, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Appellation', Aneox.Controllers.Appellation, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Contact', Aneox.Controllers.Contact, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Legal', Aneox.Controllers.Legal, 'Animations', 'Library.Locale', 'Library.Router');

    bottle.service('App', Aneox.App,
        'Library.Fonts', 'Library.Locale', 'Library.Router', 'Library.Analytics',
        'Controllers.Home','Controllers.Maison', 'Controllers.Philosophie', 'Controllers.Vinification', 'Controllers.Appellation', 'Controllers.Contact', 'Controllers.Legal');

    
    var splashState = 0;
    var loadingState = 0;

    if (Cookies.get('splashscreen') !== 'true') {
        var now = new Date();
        now.setFullYear(now.getFullYear() - 18);
        var nowText = now.toISOString().substring(0, 10).split('-');
        $('#splashscreen-mast .date').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);
        $('.check-age').css({'display': 'block'});
    }else{
        splashState = 1;
        $(".loading").css({'display': 'block'});
    }
    
     TweenMax.set('#splashscreen', { display: 'block', opacity: 1 });
     TweenMax.fromTo('#splashscreen-mast', 0.5, { display: 'block', opacity: 0 }, { opacity: 1 });

    window.acceptSplash = function() {
        
        TweenMax.to('.check-age', 0.5, { opacity: 0, display: 'none'});
        TweenMax.to('.loading', 0.5, { delay: 0.5, opacity: 1, display: 'block'});
        
        splashState = 1;
        checkLaunching();
    };

    window.refuseSplash = function() {
        window.location.href = 'https://google.fr';
    };


    lazySizesConfig.preloadAfterLoad = true;
    var lazyloadedCounter = 0;
    
    $('.lazyload').load(function(e){
        lazyloadedCounter++;
        checkLaunching();
    });
    
    window.checkLaunching = function(){

        if(lazyloadedCounter == 25 && splashState == 1){
            
            window.app = bottle.container.App;
            
            Cookies.set('splashscreen', 'true');
            TweenMax.to('#splashscreen', 0.5, { delay: 1,opacity: 0, display: 'none', onComplete: activeScroll });
            
        }
    }
    
    window.activeScroll = function(){
        $("body").css({"overflow": "scroll"})
    }
    
    
        
   

        


    

})(Aneox || (Aneox = {}));