var Aneox;
(function(Aneox) {
    'use strict';

    var App = (function() {

        var self;

        var isTouchDevice;
        var isMobile;
        
        var loadedImgCounter = 0;
        var processRouterByScroll = 0;
        
        var currentLocale;
        var currentNode;
        var currentController;

        function App(fonts, locale, router, analytics, homeController,maisonController, philosophieController, vinificationController, appellationController, contactController, legalController) {
            self = this;

            self.fonts = fonts;
            self.locale = locale;
            self.router = router;
            self.analytics = analytics;
            self.controllers = {
                home: homeController,
                maison : maisonController,
                philosophie: philosophieController,
                vinification: vinificationController,
                appellation: appellationController,
                contact: contactController,
                legal: legalController
            };
  
           
           var md = new MobileDetect(window.navigator.userAgent);
            Aneox.Data.Config.runtime = {
                isTouchDevice: 'ontouchstart' in document.documentElement,
                mobile: md.mobile(),
                supportsScrollEvents: !md.mobile() || (md.is('iOS') && md.is('Safari') && md.version('Safari') > 8)
            };

            isMobile = md.mobile();
           
            //lazySizesConfig.preloadAfterLoad = true;

            self.router.bind('pageChange', function(path, node, nodeLocale) {
                
                self.locale.setLocale(nodeLocale.locale);
                
                if (_.get(currentNode, 'id') === node.id) return;

                //self.locale.setLocale(nodeLocale.locale);

                var rootNodeId, controllerId;

                // Enter/Leave animations
                if (_.get(currentNode, 'id') !== node.id) {
                    switch (_.get(currentNode, 'type')) {
                        case 'basic-page':
                            self.controllers[currentNode.id].leave();
                            break;
                        
                    }

                    switch (node.type) {
                        
                        default:
                            if(processRouterByScroll == 0) self.controllers[node.id].enter(); processRouterByScroll = 0;
                            rootNodeId = node.id;
                            controllerId = node.id;
                        break;
                    }
                }



                if(node.id === 'legal'){
                    App.prototype.swapMenu();
                }
                
                

                // Update active menu item
                $('#desktop-menu ul li').removeClass('active');
                $('#desktop-menu ul li a[router-href="' + rootNodeId + '"]').parent().addClass('active');


                
                currentNode = node;
                currentController = self.controllers[controllerId];
            });

            self.locale.bind('localeChange', function(locale) {
                if (currentLocale === locale) return;

                // Update active language menu item
                $('#desktop-menu .language a').removeClass('active');
                $('#desktop-menu .language a[router-locale="' + locale + '"]').addClass('active');
                
                if(locale == 'fr'){
                    $(".plan img").attr("src","assets/contact/plan_fr.svg");
                }else{
                    $(".plan img").attr("src","assets/contact/plan_en.svg");
                }
                
                currentLocale = locale;

                setTimeout(handleWindowResize);
            });

            // Window resize handling
            var handleWindowResize = function() {
                var windowHeight = $(window).height();

                _.forEach($('[screen-height]'), function(element) {
                    var heightElement = $(element);
                    var topMargin = _.parseInt(heightElement.css('margin-top'));
                    var bottomMargin = _.parseInt(heightElement.css('margin-top'));
                    var height = windowHeight - topMargin - bottomMargin;

                    heightElement.innerHeight(height);
                });

                App.prototype.logoPosition();
                App.prototype.tamponsPosition();
                App.prototype.SetAnchorPosAllController(); 
                
                if (currentController && currentController.handleWindowResize) currentController.handleWindowResize.call(currentController);
            };

            var throttledWindowResizeHandler = _.throttle(handleWindowResize, 200, { leading: false, trailing: true });
            $(window).resize(throttledWindowResizeHandler);
            Visibility.change(throttledWindowResizeHandler);
            self.fonts.bind('fontsActive', handleWindowResize);
            handleWindowResize();

            
            
            // Scroll handling
            $(window).on('scroll', function (event) {
                App.prototype.swapMenu();
                App.prototype.processRouterScroll(event);
            });

            // Wheel handling
            $(window).on('mousewheel', function (event) {
                
            });

            // Touch handling
            if (Aneox.Data.Config.runtime.isTouchDevice) {
                var mc = new Hammer.Manager(document.body);
                mc.add(new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL }));
                mc.on('swipeleft swiperight', function (event) {
                });
            }

            // Keyboard handling
            $(window).keydown(function (event) {
            });

            if (Cookies.get('cookies') !== 'true') {
                TweenMax.fromTo('#cookies', 0.5, { opacity: 0, y: -50, display: 'block' }, { opacity: 1, y: 0 });
            }


            
        };
        
        App.prototype.processRouterScroll = function(position){
            if(currentNode.id === 'legal') return;

            var scrollTopElement = /WebKit/.test(navigator.userAgent) ? $(document.body) : $("html");
            
            var current;
            
            
            if(scrollTopElement.scrollTop() < (self.controllers.maison.routerAnchorPos)){
                
                if(currentNode.id !== 'home') App.prototype.setScrollRouter('home', self.controllers.home);

            }else if(scrollTopElement.scrollTop() > (self.controllers.maison.routerAnchorPos) && scrollTopElement.scrollTop() < self.controllers.philosophie.routerAnchorPos){
                
                if(currentNode.id !== 'maison') App.prototype.setScrollRouter('maison', self.controllers.maison);

            }else if(scrollTopElement.scrollTop() > self.controllers.philosophie.routerAnchorPos && scrollTopElement.scrollTop() < self.controllers.vinification.routerAnchorPos){
                
                if(currentNode.id !== 'philosophie') App.prototype.setScrollRouter('philosophie', self.controllers.philosophie);

            }else if(scrollTopElement.scrollTop() > self.controllers.vinification.routerAnchorPos && scrollTopElement.scrollTop() < self.controllers.appellation.routerAnchorPos){
                
                if(currentNode.id !== 'vinification') App.prototype.setScrollRouter('vinification', self.controllers.vinification);
                
            }else if(scrollTopElement.scrollTop() > self.controllers.appellation.routerAnchorPos && scrollTopElement.scrollTop() < (self.controllers.contact.routerAnchorPos - 50)){
                
                if(currentNode.id !== 'appellation') App.prototype.setScrollRouter('appellation', self.controllers.maison);

            }else if(scrollTopElement.scrollTop() >= (self.controllers.contact.routerAnchorPos - 50)){
               
               if(currentNode.id !== 'contact') App.prototype.setScrollRouter('contact', self.controllers.contact);

            }
            
        }
        
        App.prototype.setScrollRouter = function(nodeId, controllers){
                var scrollNode = nodeId;
                var scrollControllers = controllers;
                
                if(currentNode.id !== scrollNode){
                    processRouterByScroll = 1;
                    
                    var nodePath = self.router.getNodePath(scrollNode, currentLocale);
                    self.router.setPath(nodePath);
                    var node = self.router.getNode(scrollNode);
                    
                    //currentNode = node;
                    //currentController = scrollControllers;
                }
        }
        
        App.prototype.SetAnchorPosAllController = function(){
            _.forEach(self.controllers, function(value){
                if(value && value.routerAnchorPos) value.setAnchorPos();
            });
            
        }
        
        App.prototype.tamponsPosition = function(){
            
            var tampomMaison = $("#tampon-maison");
            var tampomPhilosophie = $("#tampon-philosophie");
            var tamponAppellation = $("#tampon-appellation");
            
            var imgOuvertureMaison = $("#img-ouverture-maison");
            var imgOuverturePhilosophie = $("#img-ouverture-philosophie");
            var imgOuvertureAppellation = $("#img-ouverture-appellation");
            
            var imgOuvertureMaisonOffset = imgOuvertureMaison.offset();
            var imgOuverturePhilosophieOffset = imgOuverturePhilosophie.offset();
            var imgOuvertureAppellationOffset = imgOuvertureAppellation.offset();
            
            var tamponMaisonPos = _.parseInt(imgOuvertureMaisonOffset.left) + _.parseInt(imgOuvertureMaison.width()) - _.parseInt(tampomMaison.width() / 2);
            
            var tamponPhilosophieXPos = _.parseInt(imgOuvertureMaisonOffset.left) - _.parseInt(tampomPhilosophie.width() / 2);
            var tamponPhilosophieYPos = _.parseInt(imgOuverturePhilosophieOffset.top) + _.parseInt(imgOuverturePhilosophie.height()) -  _.parseInt(tampomPhilosophie.width() / 2);
            
            var tamponAppellationXPos = _.parseInt(imgOuvertureAppellationOffset.left) + _.parseInt(imgOuvertureAppellation.width()) + 170;
            var tamponAppellationYPos = _.parseInt(imgOuvertureAppellationOffset.top) + 270;
            
            tampomMaison.css({'left': tamponMaisonPos});
            tampomPhilosophie.css({'left': tamponPhilosophieXPos, 'top': tamponPhilosophieYPos});
            tamponAppellation.css({'left': tamponAppellationXPos, 'top': tamponAppellationYPos});
        }
        
        App.prototype.logoPosition = function(){
            
            if($("#header-mobile-img").css('display') == 'none'){
                 var bandeauImg = $("#header-desktop-img");
            }else{
                 var bandeauImg = $("#header-mobile-img");
            }
            
            var logo = $("#logo-home");
            var logoHeight = _.parseInt(logo.height());
            
            var bandeauImgPos = bandeauImg.offset();
            var bandeauImgTopPos = _.parseInt(bandeauImgPos.top);
            var bandeauHeight = _.parseInt(bandeauImg.height());
            
            var logoYPos = (bandeauImgTopPos + bandeauHeight) - (logoHeight / 1.2);
            
            
            logo.css({'top':logoYPos});
            
        }
        
        
        App.prototype.swapMenu = function(){
                
            var scrollTopElement = /WebKit/.test(navigator.userAgent) ? $(document.body) : $("html");
            
            if($('#mobile-menu').css('display') == 'none'){
                 var currentMenu = $('#desktop-menu');
                 var currentLayer = "#desktop-menu-layer";
                 
                 var posCurrentMenu = currentMenu.offset();
                 var topCurrentMenuPos = posCurrentMenu.top;
            }else{
                 var currentMenu = $('#mobile-menu');
                 var currentLayer = "#mobile-menu-layer";
                 var topCurrentMenuPos = 180;
            }
            
            //var desktopMenu = $('#desktop-menu');
            
            
            if (scrollTopElement.scrollTop() > topCurrentMenuPos) {
                if(this.headerNavVisibility !== true){
                    TweenMax.killTweensOf(currentLayer);
                    TweenMax.to(currentLayer, 0.5, { y: 71, force3D: true });
                    this.headerNavVisibility = true;
                }
            }else{
                if (this.headerNavVisibility === true) {
                    TweenMax.killTweensOf(currentLayer);
                    TweenMax.to(currentLayer, 0.5, { y: 0, force3D: true });
                    this.headerNavVisibility = false;
                }
            }
        }

        App.prototype.swapMenu();
           
        App.prototype.toggleNavMenu = function () {
        TweenMax.killTweensOf('#mobile-menu-layer');

        if (!self.navMenuVisibility) {
        
            
            TweenMax.set('#overlay-mobile-menu', { display: 'block', height: '100%' });
            TweenMax.from('#overlay-mobile-menu', 0.5, { height: 0 });
            //TweenMax.to('#overlay-mobile-menu ul', 1, { opacity: 1 });
            
        } else {
            //TweenMax.to('#mobile-menu-layer ul', 0.5, { opacity: 0 });
            TweenMax.to('#overlay-mobile-menu', 1, { height: 0, display: 'none' });
            
            //TweenMax.set('#mobile-menu-layer', { display: 'none' });
            
        }

        self.navMenuVisibility = !self.navMenuVisibility;
    };   
           
           
            
        App.prototype.hideNavMenu = function () {
            if (!self.navMenuVisibility) return;

            TweenMax.killTweensOf('#mobile-menu-layer');

            TweenMax.to('#mobile-menu-layer', 0.5, { height: 0, display: 'none' });
            TweenMax.set('#nav-menu-background', { display: 'none' });

            self.navMenuVisibility = !self.navMenuVisibility;
        }
        

        App.prototype.acceptCookies = function() {
            Cookies.set('cookies', 'true');
            TweenMax.to('#cookies', 0.5, { opacity: 0, y: -50, display: 'none' });
        };

        /*App.prototype.acceptSplash = function() {
            Cookies.set('splashscreen', 'true');
            TweenMax.to('#splashscreen', 0.5, { opacity: 0, display: 'none' });
        };

        App.prototype.refuseSplash = function() {
            window.location.href = 'https://google.fr';
        };*/

        return App;
    })();

    Aneox.App = App;
})(Aneox || (Aneox = {}));