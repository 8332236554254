var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Home = (function() {

            var self;

            function Home(animations) {
                self = this;
                self.timeline = animations.timeline;
                
                Home.prototype.setAnchorPos();
            }

            Home.prototype.enter = function() {
                //self.timeline.set('#main-container', { display: 'block', opacity: 0 });
                //self.timeline.to('#main-container', 0.5, { opacity: 1 });
                self.timeline.to('html, body', 0.5, {scrollTop: 0});
            };

            Home.prototype.leave = function() {
                //self.timeline.to('#home', 0.5, { display: 'none', opacity: 0 });
                

            };

            Home.prototype.handleWindowResize = function() {
            };
            
             Home.prototype.setAnchorPos = function(){
                //var selfAnchor = $("#home-main");
                //var anchorPos = selfAnchor.offset();
                //var scrollPos = anchorPos.top - 90;
                
                self.routerAnchorPos = 0;
            }

            return Home;
        })();

        Controllers.Home = Home;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));
