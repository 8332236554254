var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Vinification = (function() {

            var self;
            

            function Vinification(animations) {
                self = this;
                self.timeline = animations.timeline;
                
                Vinification.prototype.setAnchorPos();
            }

            Vinification.prototype.enter = function() {
                //self.timeline.set('#main-container', { display: 'block', opacity: 0 });
                //self.timeline.to('#main-container', 0.5, { opacity: 1 });
                
                /*var selfAnchor = $("#vinification-main");
                var anchorPos = selfAnchor.offset();
                var scrollPos = anchorPos.top - 90;*/
                
               
                self.timeline.to('html, body', 0.5, {scrollTop: self.routerAnchorPos});
            };

            Vinification.prototype.leave = function() {
                //self.timeline.to('#home', 0.5, { display: 'none', opacity: 0 });
            };

            Vinification.prototype.handleWindowResize = function() {
            };
            
            Vinification.prototype.setAnchorPos = function(){
                var selfAnchor = $("#vinification-main");
                var anchorPos = selfAnchor.offset();
                var scrollPos = anchorPos.top - 90;
                
                self.routerAnchorPos = scrollPos;
            }

            return Vinification;
        })();

        Controllers.Vinification = Vinification;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));
