var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Contact = (function() {

            var self;

            function Contact(animations) {
                self = this;
                self.timeline = animations.timeline;
                
                Contact.prototype.setAnchorPos();
            }

            Contact.prototype.enter = function() {
                //self.timeline.set('#main-container', { display: 'block', opacity: 0 });
                //self.timeline.to('#main-container', 0.5, { opacity: 1 });
                
                /*var selfAnchor = $("#contact-main");
                var anchorPos = selfAnchor.offset();
                var scrollPos = anchorPos.top - 90;*/
                
                
                self.timeline.to('html, body', 0.5, {scrollTop: self.routerAnchorPos});
            };

            Contact.prototype.leave = function() {
                //self.timeline.to('#home', 0.5, { display: 'none', opacity: 0 });
            };

            Contact.prototype.handleWindowResize = function() {
            };
            
            Contact.prototype.setAnchorPos = function(){
                var selfAnchor = $("#contact-main");
                var anchorPos = selfAnchor.offset();
                var scrollPos = anchorPos.top - 90;
                
                self.routerAnchorPos = scrollPos;
                
            }

            return Contact;
        })();

        Controllers.Contact = Contact;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));
