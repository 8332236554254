var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Philosophie = (function() {
           
            var self;
            
            function Philosophie(animations) {
                self = this;
                self.timeline = animations.timeline;
                
                Philosophie.prototype.setAnchorPos();
                
            }

            Philosophie.prototype.enter = function() {
                //self.timeline.set('#main-container', { display: 'block', opacity: 0 });
                //self.timeline.to('#main-container', 0.5, { opacity: 1 });
                
                
                self.timeline.to('html, body', 0.5, {scrollTop: self.routerAnchorPos});
            };

            Philosophie.prototype.leave = function() {
                //self.timeline.to('#home', 0.5, { display: 'none', opacity: 0 });
            };

            Philosophie.prototype.handleWindowResize = function() {
                
            };
            
            Philosophie.prototype.setAnchorPos = function(){
                var selfAnchor = $("#philosophie-main");
                var anchorPos = selfAnchor.offset();
                var scrollPos = anchorPos.top - 90;
                
                self.routerAnchorPos = scrollPos;
            }

            return Philosophie;
        })();

        Controllers.Philosophie = Philosophie;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));
